<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            ref="list"
            :expandRowByClick="true"
            @expandedRowsChange="handleExpandedRowsChange($event)"
            :expandedRowRender="true"
        >
            <template slot="expandedRowRender" slot-scope="data">
                <a-table
                    :columns="columns_sub"
                    :pagination="false"
                    :dataSource="data.record.list"
                    style="margin: 0"
                >
                    <template slot="footer" slot-scope>
                        <div
                            style="text-align:right"
                        >共{{data.record.goods.goods_num}}件商品，总计：¥{{data.record.goods.goods_total}}</div>
                    </template>
                    <template
                        slot="stock_num"
                        slot-scope="text,record"
                    >{{record.stock_num}}{{record.goods_unit}}</template>
                </a-table>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { competeReport, competeInfo } from "@/api/order";
import TableList from "@/components/TableList";
const columns = [
    {
        title: "ID",
        dataIndex: "visit_id",
    },
    {
        title: "客户名称",
        dataIndex: "customer",
    },
    {
        title: "客户地址",
        dataIndex: "address",
    },
    {
        title: "商品数量",
        dataIndex: "stock_num",
    },
    {
        title: "商品总价",
        dataIndex: "total",
    },
    {
        title: "上报人",
        dataIndex: "username",
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
];

const columns_sub = [
    {
        title: "商品名称",
        dataIndex: "goods_title",
    },
    {
        title: "价格",
        dataIndex: "goods_price",
    },
    {
        title: "库存数量",
        dataIndex: "stock_num",
        scopedSlots: {
            customRender: "stock_num"
        }
    },
    {
        title: "小计",
        dataIndex: "subtotal",
    }
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: competeReport,
            columns,
            columns_sub,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "customer",
                        title: "客户",
                        placeholder: '客户名称/ID/地址',
                        options: {
                        }
                    },
                    {
                        type: "text",
                        name: "staff",
                        title: "上报人",
                        placeholder: '姓名/账号/工号/手机号',
                        options: {},
					},
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    methods: {
        async handleExpandedRowsChange(e) {
            e.forEach(item => {
                if (!this.$refs.list.list[item].list) {
                    competeInfo({
                        data: {
                            visit_id: this.$refs.list.list[item].visit_id,
                        }
                    }).then(res => {
                        let list = [];
                        this.$refs.list.list.forEach((vo, index) => {
                            if (index == item) {
                                vo.list = res.data.list.goods;
                                vo.goods = res.data.list;
                            }
                            list.push(vo)
                        })
                        this.$refs.list.set_data('list', list)
                    })
                }
            })
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>